import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ProductFilterOptions, ProductQueryData, ProductAuditQueryData, FeedbackQuery, SingleProductQueryData, ProductsDefaultsResourcesTypesQueryData, ProductsResourcesTypesQueryData, SourchFilesTypesQueryData, MappingNamingConventionsQueryData, ProductAuditFilterOptions, AllProductAudit } from '../product/product';
import { ApolloQueryResult } from '@apollo/client/core';
import { EndpointsService } from './endpoints.service';
import { StorageService } from 'ng-storage-service';
import {
  RetailerFilterOptions,
  RetailersQueryData,
  RetailerQueryInner,
  RetailersFilterOptions,
  RejectReasonQueryInner
} from '../retailer/retailer';
import { GlobalsService } from '../shared/globals.service';
import { UsersQueryData, UsersFilterOptions, UserQueryData } from '../auth/user';
import { BroadcasterService } from 'ng-broadcaster';
import { Router } from '@angular/router';
import { ProductCategoryData } from '../product-categories/product-categories';
import { ProductsScraperBatchRequestsData, ScraperBatchFilterOptions } from '../scraper/scraper';
import { JobTypesQueryData } from '../retailer/job-types';
import { UtilsService } from '../shared/utils.service';
import { FeedbackTypes } from '../product/feedback';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {
  public productsObservable: Observable<ProductQueryData>;
  constructor(
    private apollo: Apollo,
    private endpoints: EndpointsService,
    private storage: StorageService,
    private globals: GlobalsService,
    private broadcaster: BroadcasterService,
    private router: Router,
    private utils: UtilsService
  ) {
    // this.setGQL();
  }

  // private setGQL() {
  //   let endpoint = this.endpoints.getEndpointDomain(EndPoints.GRAPH);
  //   const token = this.storage.get('token');
  //   const httpLink = createHttpLink({
  //     uri: endpoint,
  //   });
  //   const errorLink = onError(({ graphQLErrors, networkError }) => {
  //     this.onRequestEnd();
  //     if (graphQLErrors) {
  //       graphQLErrors.map(({ message, locations, path }) =>
  //         console.log(
  //           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  //         ),
  //       );
  //       if (graphQLErrors[0].message == 'Unauthorized.') {
  //         this.logOut();
  //       }
  //     }

  //     if (networkError) console.log(`[Network error]: ${networkError}`);
  //   });

  //   const authLink = setContext((_, { headers }) => {
  //     // this.globals.numOfRequestsInProgress++;
  //     // get the authentication token from local storage if it exists
  //     const token = this.storage.get('token');
  //     // return the headers to the context so httpLink can read them
  //     return {
  //       headers: {
  //         ...headers,
  //         authorization: token ? token : '',
  //       }
  //     }
  //   });

  //   const defaultOptions = {
  //     watchQuery: {
  //       fetchPolicy: 'network-only',
  //       errorPolicy: 'ignore',
  //     },
  //     query: {
  //       fetchPolicy: 'network-only',
  //       errorPolicy: 'all',
  //     },
  //   } as DefaultOptions;

  //   let finalLink = errorLink;
  //   finalLink = finalLink.concat(authLink);
  //   finalLink = finalLink.concat(httpLink);

  //   this.apollo.create({
  //     link: finalLink,
  //     cache: new InMemoryCache(),
  //     defaultOptions: defaultOptions
  //   });
  // }

  // private logOut() {
  //   this.storage.remove('user');
  //   this.storage.remove('token');
  //   this.broadcaster.broadcast('onLogout');
  //   this.router.navigate(['/login']);
  // };

  public getValues(options: any): string {
    let isEmpty = (param) => {
      if (param === '') return true;
      if (param instanceof Array && !param.length) return true;
      return false;
    }
    let params = '';
    for (let i in options) {
      if (!isEmpty(options[i])) {
        let isString = typeof options[i] === 'string';
        let val = options[i];
        if (val === '[]') continue;
        if (isString)
          params += i + ':' + '"' + val + '"' + ',';
        else if (val instanceof Array) {
          if (val.length) {
            if (!(val[0] instanceof Array)) {
              let toAdd = i + ':[';
              val.forEach((inner) => {
                if (typeof inner === 'string' && (inner[0] != '"' && inner[inner.length - 1] != '"'))
                  toAdd += '"' + inner + '",'
                else
                  toAdd += '' + inner + ','
              });
              toAdd = toAdd.substring(0, toAdd.length - 1);
              params += toAdd + '],';
            }
            else {
              params += i + ':[' + val.toString() + '],';
            }
          }
        }
        else {
          params += i + ':' + val + ',';
        }
      }
    }
    if (options && Object.keys(options).length)
      params = params.substring(0, params.length - 1);
    return params;
  }

  private normalizeRetailers(options: RetailersFilterOptions): RetailersFilterOptions {
    if (options.id instanceof Array) {
      options.id.map(i => i * 1)
    }
    return options;
  }

  normalizeProduct(options: ProductFilterOptions): ProductFilterOptions {
    if (typeof options.is_desc === 'string' && options.is_desc)
      options.is_desc = options.is_desc == 'true';
    if (typeof options.is_archived === 'string' && options.is_archived)
      options.is_archived = options.is_archived == 'true';
    if (options.status_id instanceof Array) {
      let optionsAny = options as any;
      if (typeof options.status_id[0] === 'string' && optionsAny.status_id[0]['indexOf'](',') > -1)
        options.status_id = optionsAny.status_id[0]['split'](',');
      options.status_id = options.status_id.map(i => i * 1);
    }
    const getDecoded = (i: string) => {
      let res = decodeURIComponent(i);
      if (res[0] !== '"')
        res = '"' + res + '"'
      return res;
    };
    if (options.name instanceof Array && options.name.length)
      options.name = options.name.map(i => getDecoded(i));
    if (options.retailer_id instanceof Array)
      options.retailer_id.map(i => i * 1);

    if (options.date_from) {
      options.date_from = this.utils.toUtcEpoch(options.date_from, true)
    }

    if (options.date_to) {
      options.date_to = this.utils.toUtcEpoch(options.date_to, false)
    }
    return options;
  }

  private onRequestEnd() {
    this.globals.decreaseNumOfRequestsInProgress();
  }

  private onRequestStart() {
    this.globals.increaseNumOfRequestsInProgress();
  }

  public retailerForProducts(options: RetailersFilterOptions): Observable<ApolloQueryResult<RetailersQueryData>> {
    this.onRequestStart();
    options = this.normalizeRetailers(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allRetailers(${params}) {
        rows {
          id
          name
          logo_url
          retailers_categories {
            process_type
            category_name_org_lang
            id
          }
          retailers_sub_categories {
            sub_category_name_org_lang
            category_id
            id
          }
          retailers_settings {
            settings
            id
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<RetailersQueryData>({ query: query }) as Observable<ApolloQueryResult<RetailersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerForManage(options: RetailerFilterOptions): Observable<ApolloQueryResult<RetailerQueryInner>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      retailers(${params}) {
        id
        name
        retailers_users {
          users {
            email
            subscribed
            subscribed_daily_analytics
            subscribed_weekly_analytics
            subscribed_notifications
            firstname
            lastname
            avatar_url
            id
            users_roles {
              role_id
              roles {
                id
                name
              }
            }
          }
        }
      }
    }`;
    let res = this.apollo.query<RetailerQueryInner>({ query: query }) as Observable<ApolloQueryResult<RetailerQueryInner>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerForProduct(options: RetailerFilterOptions): Observable<ApolloQueryResult<RetailerQueryInner>> {
    this.onRequestStart();
    // options = this.normalizeRetailer(options);
    let params = this.getValues(options);
    let query = gql`
    {
      retailers(${params}) {
        id
        name
        zip
        address
        city
        region
        country_code
        tax_id
        allow_downloads
        website
        demo_account
        users_downloads_count
        maximum_glb_size
        visible_embedded
        auto_create_texture_offers
        realtime_image_render_price
        external_provider
        retailers_categories {
          process_type
          category_name_org_lang
          id
          price
          image_render_price
          video_render_price
          retailers_categories_renders_angles {
            angle_x
            angle_y
            screenshot
          }
        }
        retailers_sub_categories {
          id
          category_id
          artists_users_presets {
            artist_user_id
            created_at
            id
            preset_json
            preset_name
            retailer_id
            retailer_sub_category_id
            sort_index
            sub_category_id
            type_id
            updated_at
          }
          sub_category_name_org_lang
        }
        retailers_settings {
          settings
          id
        }
        retailers_resources_types {
          id
          resource_type_id
        }
        retailers_budget {
          id
          retailer_id
          amount
        }
        retailers_renders_settings {
          background_color
          duration
          frames_per_second
          product_position
          product_reflection
          product_shadow
          resolution_height
          resolution_width
          retailer_id
        }
        retailers_renders_angles {
          angle_x
          angle_y
          screenshot
        }
        retailers_polygon_specifications {
          id
          min_poly_count
          max_poly_count
          poly_type
          poly_shape_type
          retailer_id
          job_type
          variation_name
        }
        retailers_agreements {
          maximum_assets_download
        }
        retailers_contracts {
          id
          retailer_index
        }
        retailers_batches {
          avg_product_price
          contract_id
          contract_index
          created_at
          end_at
          gross_margin
          id
          retailer_id
          total_models
          name
          updated_at
          used_quota
        }
        retailers_domains {
          id
          retailer_domain
        }
      }
    }`;
    let res = this.apollo.query<RetailerQueryInner>({ query: query }) as Observable<ApolloQueryResult<RetailerQueryInner>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerForQuery(options: RetailersFilterOptions): Observable<ApolloQueryResult<RetailersQueryData>> {
    this.onRequestStart();
    options = this.normalizeRetailers(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allRetailers(${params}) {
        rows {
          id
          name
          logo_url
          retailers_contracts {
            id
            retailer_index
          }
          retailers_categories {
            process_type
            category_name_org_lang
            id
          }
          retailers_sub_categories {
            sub_category_name_org_lang
            category_id
            id
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<RetailersQueryData>({ query: query }) as Observable<ApolloQueryResult<RetailersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerBatches(options: RetailersFilterOptions): Observable<ApolloQueryResult<RetailersQueryData>> {
    this.onRequestStart();
    options = this.normalizeRetailers(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allRetailers(${params}) {
        rows {
          id
          name
          logo_url
          retailers_contracts {
            id
            retailer_index
          }
          retailers_batches {
                     avg_product_price
                     contract_id
                     contract_index
                     created_at
                     end_at
                     gross_margin
                     id
                     retailer_id
                     total_models
                     name
                     updated_at
                     contract_index
                     used_quota
             }
        }
        count
      }
    }`;
    let res = this.apollo.query<RetailersQueryData>({ query: query }) as Observable<ApolloQueryResult<RetailersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public retailerForSettings(options: RetailerFilterOptions): Observable<ApolloQueryResult<RetailerQueryInner>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      retailers(${params}) {
        id
        retailer_key
        name
        logo_url
        website
        address
        city
        region
        zip
        country_code
        tax_id
        demo_account
        allow_downloads
        use_watermark
        use_mesh_watermark
        copy_client_feedback_to_job
        automatic_approval_for_approved_items
        auto_internally_approve
        renders_auto_approve_go_live
        apply_due_date_grace
        auto_create_texture_offers
        source_file_match_approval
        is_artist_contractor
        job_qa_mode
        auto_validate_source_files
        maximum_glb_size
        visible_embedded
        use_hexa_naming
        show_description
        realtime_image_render_price
        default_category_price
        implementation_type
        preload_model
        sla_due_date
        external_provider
        expose_adjustments
        sla_feedback_due_date
        ip
        generate_uv_layouts
        studio_type
        retailers_account_managers {
          id
          user_id
          users {
            id
            firstname
            lastname
            email
          }
        }
        retailers_categories {
          category_name_org_lang
          id
          price
          image_render_price
          video_render_price
          product_category_id
          process_type
          text
          retailers_categories_renders_angles {
            angle_x
            angle_y
            screenshot
            category_id
            id
          }
        }
        retailers_sub_categories {
          sub_category_name_org_lang
          category_id
          product_sub_category_id
          id
          estimated_traffic
          retailers_sub_categories_specifications {
            id
            title
            attachment
            sort_index
            job_type
            text
          }
        }
        retailers_settings {
          settings
          id
        }
        retailers_configs {
          config
          id
          type
        }
        retailers_scrapers_configs {
          scraper_config
          id
          retailer_id
        }
        retailers_domains {
          id
          retailer_domain
        }
        retailers_paths {
          id
          path
        }
        retailers_specifications {
          id
          title
          attachment
          text
          job_type
        }
        retailers_source_files_types {
          id
          source_type_id
          retailer_id
        }
        retailers_resources_types {
          id
          resource_type_id
          retailer_id
        }
        retailers_agreements {
          id
          created_at
          updated_at
          retailer_id
          business_plan_id
          start_at
          end_at
          price_per_api_call
          payment_model
          agreement_limit
          monthly_credit
          credit_type
          minimum_monthly_assets
          maximum_assets_download
          agreement_budget
          agreement_budget_type
          fixed_payment
          is_poc
        }
        retailers_viewer_params {
          viewer_param_key
          viewer_param_value
        }
        retailers_mesh_conventions {
          id
          retailer_id
          mesh_name
          mesh_description
        }
        retailers_mapping_naming_convention {
          id
          mapping_id
          mapping_value
        }
        retailers_budget {
          id
          retailer_id
          amount
        }
        retailers_renders_settings {
          background_color
          duration
          frames_per_second
          id
          product_position
          product_reflection
          product_shadow
          resolution_height
          resolution_width
          retailer_id
          default_video_render_price
          default_image_render_price
        }
        retailers_renders_angles {
          id
          retailer_id
          angle_x
          angle_y
          screenshot
        }
        retailers_polygon_specifications {
          id
          min_poly_count
          max_poly_count
          poly_type
          poly_shape_type
          retailer_id
          job_type
          variation_name
        }
        retailers_contracts {
          contract_type
          start_at
          end_at
          total_models
          total_price
          signed_at
          id
          retailer_index
        }
        retailers_batches {
          avg_product_price
          contract_id
          contract_index
          created_at
          end_at
          gross_margin
          sla_feedback_due_date
          sla_due_date
          id
          retailer_id
          total_models
          name
          updated_at
          contract_index
          used_quota
          retailers_batches_specifications{
            id
            title
            attachment
            sort_index
            job_type
            text
          }
         }
      }
    }`;
    let res = this.apollo.query<RetailerQueryInner>({ query: query }) as Observable<ApolloQueryResult<RetailerQueryInner>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productsAudit(options: ProductAuditFilterOptions): Observable<ApolloQueryResult<AllProductAudit>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allProductsAudit(${params}) {
        rows {
          created_at
          product_id
          user_id
          reference_table
          reference_id
          action_type
          field_name
          old_value
          created_at
          new_value
          id
          users {
            firstname
            lastname
            avatar_url
          }
          products{
            id
            name
            retailer_id
            retailers {
              name
            }
            products_data {
              id
              product_id
              url
              sort_index
              small_image_url
            }
          }
        }
        count
      }
    }
    `;

    let res = this.apollo.query<any>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<AllProductAudit>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }


  public fullProducts(options: ProductFilterOptions): Observable<ApolloQueryResult<ProductQueryData>> {
    this.onRequestStart();
    options = this.normalizeProduct(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allProducts(${params}) {
        rows {
          id
          created_at
          retailer_id
          name_org_lang
          status_id
          enabled
          mobile_enabled
          mobile_traffic
          desktop_traffic
          retailer_category_id
          retailer_sub_category_id
          price
          dimensions
          currency
          color
          material
          due_date
          notes
          width
          height
          length
          units
          visible
          is_archived
          ia
          reject_id
          qr_url
          qr_direct_url
          allow_downloads
          external_provider
          is_poc
          source_pid
          ip
          process_type
          auto_create_texture_offers
          priority_type
          priority
          retailers {
            id
            name
            allow_downloads
            users_downloads_count
            maximum_glb_size
            visible_embedded
            external_provider
            auto_create_texture_offers
            realtime_image_render_price
            studio_type
            expose_adjustments
            retailers_settings {
              settings
              id
            }
          }
          artists_items {
            id
            product_id
          }
          products_resources {
            resource_big
            resource_small
            resource_default
            viewer_resource_type
            resource_info
            resource_mobile_order
            resource_order
            created_at
            resource_text
            viewer_type
            id
            resource_enabled
            product_id
            resource_ar_url
            resource_arcore_url
            resource_type
            viewer_resource_type
            resource_poly_type
            poly_variation_name
            serial_number
            resource_originator
            uploaded_to_client_storage
          }
          process_type
        }
        summary
        count
        resource_count
      }
    }
    `;

    let res = this.apollo.query<ProductQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }


  public products(options: ProductFilterOptions): Observable<ApolloQueryResult<ProductQueryData>> {
    this.onRequestStart();
    options = this.normalizeProduct(options);
    let params = this.getValues(options);
    let query = gql`
    {
      allProducts(${params}) {
        rows {
          id
          created_at
          retailer_id
          name_org_lang
          status_id
          enabled
          mobile_enabled
          mobile_traffic
          desktop_traffic
          ia
          reject_id
          due_date
          retailers_categories {
            process_type
            category_name_org_lang
            id
          }
          retailers_sub_categories {
            sub_category_name_org_lang
            category_id
            id
          }
          artists_items {
            id
          }
          products_resources {
            resource_big
            resource_small
            resource_default
            viewer_resource_type
            resource_type
            id
            product_id
            resource_enabled
          }
          products_data {
            url
            small_image_url
            id
            sort_index
          }
          products_urls {
            url
            id
          }
          products_tags {
            tag
            confidence
            id
          }
          products_identifiers {
            serial_number
          }
          products_reject_reasons {
             id
             reason
          }
          auto_create_texture_offers
        }
        summary
        count
        resource_count
      }
    }
    `;

    let res = this.apollo.query<ProductQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public product(id: number): Observable<ApolloQueryResult<SingleProductQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products(id: ${id}) {
        id
        created_at
        retailer_id
        name_org_lang
        status_id
        enabled
        mobile_enabled
        mobile_traffic
        desktop_traffic
        retailer_category_id
        retailer_sub_category_id
        price
        dimensions
        currency
        color
        material
        due_date
        notes
        width
        height
        length
        units
        visible
        is_archived
        ia
        reject_id
        qr_url
        qr_direct_url
        allow_downloads
        external_provider
        is_poc
        source_pid
        ip
        process_type
        auto_create_texture_offers
        priority_type
        priority
        products_polygon_specifications {
          id
          product_id
          min_poly_count
          max_poly_count
          poly_type
          poly_shape_type
          job_type
          variation_name
          serial_number
        }
        retailers {
          id
          name
          allow_downloads
          users_downloads_count
          maximum_glb_size
          visible_embedded
          external_provider
          auto_create_texture_offers
          realtime_image_render_price
          studio_type
          expose_adjustments
          artists_users_presets {
            id
            artist_user_id
            preset_name
            preset_json
            type_id
            sub_category_id
            retailer_sub_category_id
            retailer_id
            sort_index
          }
          retailers_categories {
            category_name_org_lang
            id
            price
            image_render_price
            video_render_price
            process_type
            retailers_categories_renders_angles {
              angle_x
              angle_y
              screenshot
            }
          }
          retailers_sub_categories {
            sub_category_name_org_lang
            category_id
            id
            artists_users_presets {
              artist_user_id
              created_at
              id
              preset_json
              preset_name
              retailer_id
              retailer_sub_category_id
              sort_index
              sub_category_id
              type_id
              updated_at
            }
          }
          retailers_settings {
            settings
            id
          }
          retailers_budget {
            id
            retailer_id
            amount
          }
          retailers_renders_settings {
            background_color
            duration
            frames_per_second
            product_position
            product_reflection
            product_shadow
            resolution_height
            resolution_width
            retailer_id
          }
          retailers_renders_angles {
            angle_x
            angle_y
            screenshot
          }
          retailers_polygon_specifications {
            min_poly_count
            max_poly_count
            poly_type
            poly_shape_type
          }
          retailers_agreements {
            maximum_assets_download
          }
          retailers_contracts {
            id
            retailer_index
          }
          retailers_batches
          {
            name
            id
            contract_id
            contract_index
            total_models
            end_at
            used_quota
          }
        }
        products_categories {
          id
          description
        }
        artists_items {
          id
          product_id
        }
        products_resources {
          resource_big
          resource_small
          resource_default
          viewer_resource_type
          resource_info
          resource_mobile_order
          resource_order
          created_at
          resource_text
          viewer_type
          id
          resource_enabled
          product_id
          resource_ar_url
          resource_arcore_url
          resource_type
          viewer_resource_type
          resource_poly_type
          poly_variation_name
          serial_number
          resource_originator
          uploaded_to_client_storage
          products_resources_feedback {
            id
            created_at
            updated_at
            feedback_type
            opened_by
            title
            screenshot
            notes
            products_resources_feedback_comments {
              id
              created_at
              updated_at
              user_id
              comment
            }
          }
          products_resources_source_files {
            id
            software_id
            uploaded_to_client_storage
            software_enum {
              id
              software_name
            }
          }
          products_resources_files_details {
            file_usage_type
            file_name
            file_size
          }
          assets_details {
            poly_count
            vert_count
            width
            height
            length
            units
          }
          artists_jobs_resources {
            artists_jobs_items {
              job_id
              artists_jobs {
                is_dummy
                artists_jobs_types {                  
                  type_id
                }
              }
            }
          }
        }
        products_data {
          id
          product_id
          url
          small_image_url
          id
          sort_index
          products_resources_alignments {
            id
            url
            config_json
            public
            data_id
            created_at
            resource_id
          }
        }
        products_urls {
          product_id
          url
          id
        }
        products_tags {
          product_id
          tag
          confidence
          id
        }
        products_specs {
          product_id
          spec_key
          spec_value
          id
        }
        products_audit {
          user_id
          reference_table
          reference_id
          action_type
          field_name
          old_value
          new_value
          created_at
          id
          users {
            firstname
            lastname
            avatar_url
          }
        }
        products_resources_types {
          id
          resource_type_id
          product_id
          resources_types {
            resource_name
            id
          }
        }
        products_identifiers {
          serial_number
          variation_id
          identifier_type
          id
        }
        products_renders_requests {
          id
          status_id
          background_color
          duration
          frames_per_second
          enabled
          product_position
          product_reflection
          product_shadow
          resolution_height
          resolution_width
          products_renders_requests_types {
            id
            type_id
          }
          products_renders_requests_angles {
            angle_x
            angle_y
            screenshot
          }
        }
        products_attachments {
          id
          created_at
          updated_at
          product_id
          title
          attachment
          attachment_type
          sort_index
          job_type
        }
        products_batches {
          batch_id
          created_at
          id
          product_id
          updated_at
        }
        products_reject_reasons {
          id
          reason
       }
       process_type
      }
    }
    `;
    let res = this.apollo.query<SingleProductQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<SingleProductQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public feedback(id: number): Observable<ApolloQueryResult<FeedbackQuery>> {
    this.onRequestStart();
    let query = gql`
    {
      products_resources_feedback(id: ${id}) {
        id
        created_at
        screenshot
        notes
        feedback_type
        feedback_sub_type
        opened_by
        fixed
        fix_due_date
        paused_at
        feedback_sub_types {
             id
             description
        }
        feedback_types {
             id
             description
        }
        products_resources_feedback_comments {
          comment
          created_at
          user_id
          users {
            firstname
            lastname
            id
          }
        }
        users {
          firstname
          lastname
          id
        }
      }
    }
    `;
    let res = this.apollo.query<FeedbackQuery>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<FeedbackQuery>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productAudit(productId: number): Observable<ApolloQueryResult<ProductAuditQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_audit(product_id: ${productId}) {
        product_id
        user_id
        reference_table
        reference_id
        action_type
        field_name
        old_value
        created_at
        new_value
        id
        users {
          firstname
          lastname
          avatar_url
        }
      }
    }
    `;
    let res = this.apollo.query<ProductAuditQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductAuditQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public users(options: UsersFilterOptions): Observable<ApolloQueryResult<UsersQueryData>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allUsers(${params}) {
        rows {
          id
          firstname
          lastname
          avatar_url
          email
          users_roles {
            roles {
              id
              name
            }
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<UsersQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UsersQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public defaultResourceTypes(): Observable<ApolloQueryResult<ProductsDefaultsResourcesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      products_defaults_resources_types {
        resource_type_id
        resources_types {
          id
          resource_name
        }
      }
    }
    `;
    let res = this.apollo.query<ProductsDefaultsResourcesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductsDefaultsResourcesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public resourceTypes(): Observable<ApolloQueryResult<ProductsResourcesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      resources_types {
        id
        resource_name
        type
      }
    }
    `;
    let res = this.apollo.query<ProductsResourcesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<ProductsResourcesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public sourchFilesTypes(): Observable<ApolloQueryResult<SourchFilesTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      source_files_types {
        id
        description
      }
    }
    `;
    let res = this.apollo.query<SourchFilesTypesQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<SourchFilesTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public mappingNamingConventions(): Observable<ApolloQueryResult<MappingNamingConventionsQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      mapping_naming_convention {
        id
        mapping_name
      }
    }
    `;
    let res = this.apollo.query<MappingNamingConventionsQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<MappingNamingConventionsQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public user(id: number): Observable<ApolloQueryResult<UserQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      users(id: ${id}) {
        id
        email
        avatar_url
        firstname
        lastname
        retailers_users {
          id
          retailer_id
          retailers {
            allow_downloads
            use_watermark
            visible_embedded
            use_hexa_naming
            show_description
            id
            logo_url
            name
            address
            website
            city
            region
            zip
            country_code
            tax_id
            retailer_key
            retailers_budget {
              id
              retailer_id
              amount
            }
          }
        }
        users_roles {
          role_id
          roles {
            id
            name
          }
        }
        users_mails_subscriptions {
          id
          mail_category_id
          subscribed
        }
        users_mails_subscriptions_intervals {
          id
          interval_id
          mail_category_id
          subscribed
        }
      }
    }
    `;
    let res = this.apollo.query<UserQueryData>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<UserQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  // public categories(id: number): Observable<ApolloQueryResult<CategoriesInnerQueryData>> {
  //   this.onRequestStart();
  //   let query = gql`
  //   {
  //     retailersCategories(retailer_id: ${id}) {
  //       retailers_categories {
  //         id
  //         category_name_org_lang
  //         price
  //       }
  //       retailers_sub_categories {
  //         id
  //         sub_category_name_org_lang
  //         category_id
  //       }
  //     }
  //   }
  //   `;
  //   let res = this.apollo.query<CategoriesInnerQueryData>(
  //     {
  //       query: query
  //     }
  //   ) as Observable<ApolloQueryResult<CategoriesInnerQueryData>>;
  //   res.subscribe(this.onRequestEnd.bind(this));
  //   return res;
  // }

  // public productsRendersRequests(id: number): Observable<ApolloQueryResult<CategoriesInnerQueryData>> {
  //   this.onRequestStart();
  //   let query = gql`
  //   {
  //     products_renders_requests(id: ${id}) {
  //       id
  //       created_at
  //       updated_at
  //       render_batch_id
  //       product_id
  //       status_id
  //     }
  //   }
  //   `;
  //   let res = this.apollo.query<CategoriesInnerQueryData>(
  //     {
  //       query: query
  //     }
  //   ) as Observable<ApolloQueryResult<CategoriesInnerQueryData>>;
  //   res.subscribe(this.onRequestEnd.bind(this));
  //   return res;
  // }

  public productsCategories(): Observable<ApolloQueryResult<ProductCategoryData>> {
    this.onRequestStart();
    let query = gql`
    {
      productsCategories {
        id
        description
        parent_description
        parent_id
        products_sub_categories {
          id
          description
          category_id
        }
      }
    }`;
    let res = this.apollo.query<ProductCategoryData>({ query: query }) as Observable<ApolloQueryResult<ProductCategoryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public scraperBatch(options: ScraperBatchFilterOptions): Observable<ApolloQueryResult<ProductsScraperBatchRequestsData>> {
    this.onRequestStart();
    let params = this.getValues(options);
    let query = gql`
    {
      allProductsScraperBatchRequests(${params}) {
        rows {
          user_id
          retailer_id
          batch_id
          status_id
          products_scraper_batch_requests_identifiers {
            identifier
            error_message
            products_scraper_batch_requests_identifiers_created {
              products {
                id
                name
              }
            }
          }
        }
        count
      }
    }`;
    let res = this.apollo.query<ProductsScraperBatchRequestsData>({ query: query }) as Observable<ApolloQueryResult<ProductsScraperBatchRequestsData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public jobsTypes(): Observable<ApolloQueryResult<JobTypesQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      jobs_types {
        id
        description
      }
    }`;
    let res = this.apollo.query<JobTypesQueryData>({ query: query }) as Observable<ApolloQueryResult<JobTypesQueryData>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  public productsRejectReasons(): Observable<ApolloQueryResult<RejectReasonQueryInner>> {
    this.onRequestStart();
    let query = gql`
    {
      products_reject_reasons {
        id
        reason
      }
    }
    `;
    let res = this.apollo.query<RejectReasonQueryInner>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<RejectReasonQueryInner>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }

  
  public feedbackTypes(): Observable<ApolloQueryResult<FeedbackTypes>>{
    this.onRequestStart();
    let query = gql`
    {
      feedbackTypes(order_by: "display_order", is_desc: false) {
       id
       description  
       feedback_sub_types {
         id
         description     
         feedback_type_id
        }
      }
    }
    `;
    let res = this.apollo.query<FeedbackTypes>(
      {
        query: query
      }
    ) as Observable<ApolloQueryResult<FeedbackTypes>>;
    res.subscribe(this.onRequestEnd.bind(this));
    return res;
  }
}
